<template>
    <div class="custom-area">
        <h2>海外用户</h2>
        <div class="custom-list">
            <img src="~@/assets/customs/haiwai.png" alt="">
        </div>
        <h2>国内用户</h2>
        <div class="custom-list">
            <img src="~@/assets/customs/guonei.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.custom-area {
    h2 {
        height:60px;
        line-height: 60px;
        font-weight:normal;
    }
}
.custom-list {
    img {
        width:100%;
    }
}
</style>