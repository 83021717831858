<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
      <Header style="margin-top:-88px;position: relative;"></Header>
      <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
      <div class="probanner">
        <div class="probannerInner about">
          <div class="banner_img ">
              <div class="titleBan">
                  <span class="tit">关于我们</span> 
                  <span class="con">/ABOUT AS</span>
              </div>            
              <div class="contentBan">专注于数据服务，专业的数据图书馆解决方案提供商和数据资源供应商</div>            
          </div>
        </div>
      </div>
      <div class="prowrap wrapColor" style="min-height:auto">
        <div class="proside cleft">
          <ul class="sideGourp">
            <li class="sideItem" v-for="(item,key) in sideList" :key="key">
              <div
                class="titles"
                :class="sideActive==item.id?'current':''"
                @click="getGroupClick(item)"
              >
              <span :class="sideActive==item.id?'cheks':''"></span>
              {{item.name}}
              </div>
            </li>
          </ul>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==1">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">公司介绍</span>
            </div>
          </div>
          <div class="paragraph-box" v-if="urlData==='sozdata'">
            <p class="paragraph" style="margin-top:20px">北京搜知数据科技有限公司（EPS数据）成立于2008年，总部位于北京市海淀区，是一家拥有先进开发技术和自主知识产权的高新技术企业。公司以“创造数据服务新模式”为使命，以“聚焦数据、创新同德”为发展理念，专注于数据应用、软件开发、信息咨询，致力于为用户提供简单、专业、高品质的数据服务，是专业的数据服务提供商、产品及项目方案解决商。</p>
            <p class="paragraph">公司自成立以来，始终秉持以用户需求为基础，以数据技术开发为先导，以市场为导向，注重技术及数据储备能力投入，开发重构数据服务的新模式，全力打造中国最易用的数据支持平台。通过汇集整合国内外丰富的数据资源，提供数据管理、数据分析、趋势预测等一站式服务，帮助用户探究底层逻辑和发展规律，为学术研究、决策制定和个性化需求提供强大助力。</p>
            <p class="paragraph">公司拥有国家高新技术企业与中关村高新技术企业的双高资质，以及近150项软件著作权与国家发明专利，是大数据行业的务实者和领跑者。自主研发的“EPS数据平台”、“中国微观经济数据查询系统”、“知图平台”、“搜知计蒙数据管理系统”、“中国区域研究数据支撑平台”、“长江经济带大数据平台”、“中国革命老区大数据平台”以及多样的定制化服务项目，得到了广大用户的一致好评。</p>
            <p class="paragraph">多年来，公司一直积极参与数字化转型、区域经济发展、图书馆数据资源建设、数字信息素养提升等方向的研究讨论和实践探索，多次承办、协办全国及省市级别专题会议和技能竞赛，获得了广泛的社会影响力和高度评价。</p>
            <p class="paragraph">公司全球网络化的布局为客户提供更专业的贴心服务，目前已在广州、杭州、长沙、上海成立四家分公司，并在湖南桃源投建占地50余亩的大数据产业园，为国内外上千家高等院校、政府机关、科研院所、企事业单位等用户提供数据及资源服务，并与多家机构共建合作基地。</p>
            <p class="paragraph">我们视每一次与客户的合作为一次自身成长的机会，愿意以自身不断进步的技术实力、数据储备实力为依托，全心全意为客户提供优质的服务，与广大客户一起拥抱大数据时代的到来。</p>
          </div>
          <div class="paragraph-box" v-else>
            <p class="paragraph" style="margin-top:20px">EPS数据成立于2008年，总部位于北京市海淀区，是一家拥有先进开发技术和自主知识产权的高新技术企业。公司以“创造数据服务新模式”为使命，以“聚焦数据、创新同德”为发展理念，专注于数据应用、软件开发、信息咨询，致力于为用户提供简单、专业、高品质的数据服务，是专业的数据服务提供商、产品及项目方案解决商。</p>
            <p class="paragraph">公司自成立以来，始终秉持以用户需求为基础，以数据技术开发为先导，以市场为导向，注重技术及数据储备能力投入，开发重构数据服务的新模式，全力打造中国最易用的数据支持平台。通过汇集整合国内外丰富的数据资源，提供数据管理、数据分析、趋势预测等一站式服务，帮助用户探究底层逻辑和发展规律，为学术研究、决策制定和个性化需求提供强大助力。</p>
            <p class="paragraph">公司拥有国家高新技术企业与中关村高新技术企业的双高资质，以及近150项软件著作权与国家发明专利，是大数据行业的务实者和领跑者。自主研发的“EPS数据平台”、“中国微观经济数据查询系统”、“知图平台”、“搜知计蒙数据管理系统”、“中国区域研究数据支撑平台”、“长江经济带大数据平台”、“中国革命老区大数据平台”以及多样的定制化服务项目，得到了广大用户的一致好评。</p>
            <p class="paragraph">多年来，公司一直积极参与数字化转型、区域经济发展、图书馆数据资源建设、数字信息素养提升等方向的研究讨论和实践探索，多次承办、协办全国及省市级别专题会议和技能竞赛，获得了广泛的社会影响力和高度评价。</p>
            <p class="paragraph">公司全球网络化的布局为客户提供更专业的贴心服务，目前已在广州、杭州、长沙、上海成立四家分公司，并在湖南桃源投建占地50余亩的大数据产业园，为国内外上千家高等院校、政府机关、科研院所、企事业单位等用户提供数据及资源服务，并与多家机构共建合作基地。</p>
            <p class="paragraph">我们视每一次与客户的合作为一次自身成长的机会，愿意以自身不断进步的技术实力、数据储备实力为依托，全心全意为客户提供优质的服务，与广大客户一起拥抱大数据时代的到来。</p>
          </div>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==2">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">企业资质</span>
            </div>
          </div>
          <div class="paragraph-box">
            <div class="proimglist" v-if="urlData==='sozdata'">
              <ul>
                <li>
                  <div class="item_img cleft">
                    <img src="~@/assets/szgxqy.png" style="width:428px;height:289px" alt="知识产权">
                  </div>
                  <div class="item_img cleft">
                    <img src="~@/assets/souzhizhongguancun.png" style="width:428px;height:289px" alt="知识产权">
                  </div>
                  <div class="clear"></div>
                </li> 
                <li>
                  <div class="ifontbos cleft">国家高新技术企业证书</div>
                  <div class="ifontbos cleft">中关村高新技术企业证书</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="item_img cleft"><img src="/img/soz/3.png" style="width:428px;height:289px" alt="知识产权"></div>
                  <div class="item_img cleft"><img src="~@/assets/szcbw.png" style="width:428px;height:289px" alt="知识产权"></div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="ifontbos cleft">搜知数据营业执照</div>
                  <div class="ifontbos cleft">出版物经营许可证</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="item_img cleft"><img src="/img/soz/12.png" style="width:428px;height:289px" alt="知识产权"></div>
                  <div class="item_img cleft"><img src="/img/soz/13.png" style="width:428px;height:289px" alt="知识产权"></div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="ifontbos cleft">AAA信用等级证书</div>
                  <div class="ifontbos cleft">AAA资质等级证书</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="itembox cleft"><img src="/img/soz/8.png" style="width:280px;height:400px;" alt="知识产权"></div>
                  <div class="itembox cleft"><img src="/img/soz/10.png" style="width:280px;height:400px;" alt="知识产权"></div>
                  <div class="itembox cleft"><img src="/img/soz/7.png" style="width:280px;height:400px;" alt="知识产权"></div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="ifontbos iw cleft">ICP证书</div>
                  <div class="ifontbos iw cleft">质量体系认证（中文版）</div>
                  <div class="ifontbos iw cleft">环境体系认证（中文版）</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <img src="/img/soz/11.png" width="800" alt="知识产权" />
                </li>
                <li>软件著作权</li>
              </ul>
            </div>
            <div class="proimglist" v-else>
              <ul>
                <li>
                  <div class="item_img cleft">
                    <img src="~@/assets/fukagaoxin.png" style="width:428px" alt="国家高新技术企业证书" />
                  </div>
                  <div class="item_img cleft">
                    <img src="~@/assets/fukazhongguancungaoxin.png" style="width:428px" alt="中关村高新技术企业证书" />
                  </div>
                </li>
                <li>
                  <div class="ifontbos cleft">国家高新技术企业证书</div>
                  <div class="ifontbos cleft">中关村高新技术企业证书</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="item_img cleft">
                    <img src="~@/assets/fukayingyezhizhao.png" style="width:428px;height:294px" alt="营业执照" />
                  </div>
                  <div class="item_img cleft">
                    <img src="~@/assets/fkcbw.png" style="width:428px;height:294px" alt="出版物经营许可证" />
                  </div>
                </li>
                <li>
                  <div class="ifontbos cleft">营业执照</div>
                  <div class="ifontbos cleft">出版物经营许可证</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="item_img cleft">
                    <img src="~@/assets/fukaICP.png" style="width:428px" alt="ICP证书" />
                  </div>
                  
                  <div class="clear"></div>
                </li>
                <li>
                  <div class="ifontbos cleft">ICP证书</div>
                  <div class="clear"></div>
                </li>
                <li>
                  <img src="~@/assets/fukazhuanli.png" alt />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==3">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">典型用户</span>
            </div>
          </div>
          <div class="paragraph-box">
            <about-custom />
          </div>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==4">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">招贤纳士</span>
            </div>
          </div>
          <div class="paragraph-box">
            <div class="job-list">
              <ul>
                <li class="active">
                  <h3 class="job-title" @click="getJobData(1)" :class="jobActive==1?'jobcurrens':''">
                    销售经理（成都、北京、杭州、福建、安徽、武汉）
                    <span class="gwicon" :class="jobActive==1?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==1">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、所辖区域内进行公司产品推广和销售工作，确保销售与市场指标的达成；</li>
                      <li>2、所辖区域内市场活动、市场专项工作、培训活动等的策划、组织、协调和实施；</li>
                      <li>3、所辖区域内市场信息的收集、反馈，以及对所辖区域的市场状况充分了解、分析，提出合理化建议或相应解决方案。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、大学专科或以上学历，专业不限，男女不限；</li>
                      <li>2、熟练掌握Office办公软件及网络应用；</li>
                      <li>3、具备良好的学习能力、沟通能力、公关能力，</li>
                      <li>4、热爱销售工作，能够承受一定的工作压力；</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(2)" :class="jobActive==2?'jobcurrens':''">
                    市场专员/主管（侧重于文案策划）
                    <span class="gwicon" :class="jobActive==2?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==2">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、负责公司微信公众号运营，包括文案撰写及图片处理</li>
                      <li>2、策划并配合执行品牌市场活动（线上&amp;线下）相关工作</li>
                      <li>3、配合区域销售完成市场推广相关工作</li>
                      <li>4、负责公司官网、产品网站等渠道内容丰富与更新</li>
                      <li>5、协助完成宣传资料的设计更新、礼品的创新设计制作</li>
                      <li>6、拓展媒体合作渠道、维护合作关系</li>
                      <li>7、协助完成培训的调研及宣传推广等工作</li>
                      <li>8、负责竞品信息调研、市场动态等相关分析工作</li>
                      <li>9、负责部门档案管理等相关工作</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、擅长文案撰写，运营自有公众号优先；</li>
                      <li>2、熟练使用Photoshop或者AI等设计软件；</li>
                      <li>3、有微视频制作经验；</li>
                      <li>4、擅长活动策划与执行，有院校大型活动策划及执行经验优先；</li>
                      <li>5、有图情专业、经济学科背景优先；</li>
                      <li>6、性格开朗、乐观，具有工作热情和团队精神。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(3)" :class="jobActive==3?'jobcurrens':''">
                    产品数据专员
                    <span class="gwicon" :class="jobActive==3?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==3">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、维护公司数据产品平台，主要包含：</li>
                      <li>（1）国外类数据库的资源下载、指标框架建立、数据录（导）入；</li>
                      <li>（2）国内年鉴类数据库的指标框架体系新建、优化以及少量数据录入工作；</li>
                      <li>（3）与数据资源部共同做好国内年鉴类数据库之外的其它类型数据库数据更新（主要为贸易数据、上市公司数据、国外数据等）；</li>
                      <li>（4）协助开发部做好程序化导入数据的后期处理；</li>
                      <li>2、 公司承接的项目中若有关于时间序列资源的需求，则配合其它部门做好项目时间序列资源的整理；</li>
                      <li>3、做好营销支持任务，积极响应公司营销需求；</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、经济学、统计学等相关专业，优秀毕业生可优先考虑；</li>
                      <li>2、对统计指标体系有较强的理解和组织能力，工作认真，有耐心，能承受较强的工作压力，有团队合作精神；</li>
                      <li>3、EXCEL操作熟练，熟悉SPSS、VBA优先。</li>
                    </ol>
                    <div class="btn">
                      <a href>申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(4)" :class="jobActive==4?'jobcurrens':''">
                    产品经理
                    <span class="gwicon" :class="jobActive==4?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==4">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、负责产品的全过程管理，包括用户需求分析，市场和竞品分析，产品规划和设计；</li>
                      <li>2、负责具体产品功能的设计，通过数据分析或敏锐观察不断推进产品优化，在提升用户体验的同时提升产品商业转化效率；</li>
                      <li>3、在产品推进过程中，主导跨部门的协调和沟通工作，能够协调各类资源以确保产品顺利发展，推动研发团队按时完成产品开发、高质量上线；</li>
                      <li>4、并配合业务部门完成产品的推广工作；</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、本科及以上学历，统计学、数量经济学、数学、计算机等专业优先；</li>
                      <li>2、3年以上互联网产品工作经验，具备数据统计分析能力，对数据敏感性，工作仔细严谨；</li>
                      <li>3、熟练使用Axure、Mindmanger/Xmind等工具，熟悉PRD相关工作；</li>
                      <li>4、做事以结果为导向，自驱能力强，具有创新精神，逻辑思维严谨，注重团队协作；</li>
                      <li>5、优秀的沟通与协调能力，有和UI、开发、测试、市场、运营等跨部门协作的经验；</li>
                      <li>6、善于思考，有很强的逻辑思维能力和解决问题的能力；</li>
                      <li>7、有数据建模、数据可视化经验者优先；</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(5)" :class="jobActive==5?'jobcurrens':''">
                    项目经理
                    <span class="gwicon" :class="jobActive==5?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==5">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1. 协助业务人员对接市场及客户需求，调研、分析项目需求；</li>
                      <li>2. 依据需求编写高水平、高质量解决方案，包含但不限于具体方案及报价；</li>
                      <li>3. 负责项目管理工作：组织项目实施、项目产品交付并负责项目运维沟通，项目组织过程资产（项目文件、项目实体）管理；</li>
                      <li>4. 对项目的质量、进度、成本负责，对项目风险进行控制，负责项目干系人及沟通管理，第三方供应商及采购管理；</li>
                      <li>5. 负责对业务人员进行解决方案培训；</li>
                      <li>6. 掌握公司产品体系、服务体系，能够灵活应用在项目业务过程（宣讲、方案编制、项目实施、项目运维等）；</li>
                      <li>7. 对上级负责，定期汇报工作进展，完成上级交代的其他工作。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1. 具备快速学习能力；</li>
                      <li>2. 具备方案讲解、需求调研、客户方案谈判和技术方案的撰写能力；</li>
                      <li>3. 具备项目管理能力：项目进度管理、需求（范围）管理、质量管理；</li>
                      <li>4. 具备较强的抗压能力，有较强的人际沟通技巧和客户亲和力；</li>
                      <li>5. 诚实守信，有强烈的事业心、责任心、自信心和积极的工作态度，可接受出差；</li>
                      <li>6. 本科以上学历，计算机及相关专业，教育系统、数据服务行业的项目经验者优先。</li>
                    </ol>
                    <div class="btn">
                      <a href>申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(6)" :class="jobActive==6?'jobcurrens':''">
                    研发部门经理/总监
                    <span class="gwicon" :class="jobActive==6?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==6">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、执行公司研发策略，带领团队完成公司研发任务；</li>
                      <li>2、负责公司研发团队的技术开发和技术管理工作，制定良好的技术开发规范，持续提升研发团队整体的技术能力。</li>
                      <li>3、负责开展产品核心技术研究和攻关工作，支撑公司科研项目。</li>
                      <li>4、带领团队支持公司重点项目定制开发。</li>
                      <li>5、协助公司制定并落实研发技术路线，完成公司交办的技术研发工作及其他任务。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、5年以上技术管理经验，领导过百万级以上软件项目开发。</li>
                      <li>2、开发技术较全面，对流行的软件开发平台与数据库都有较深了解。</li>
                      <li>3、有一定的技术前瞻性能力，对网络安全、云计算、大数据分析等业界发展动态有比较密切的关注和较深的理解，能前瞻性提出行业解决方案。</li>
                      <li>4、有自己专长的行业业务背景，做过相关行业解决方案，有教育行业大数据背景的优先。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(7)" :class="jobActive==7?'jobcurrens':''">
                    JAVA工程师
                    <span class="gwicon" :class="jobActive==7?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==7">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、负责公司产品的后端框架设计与开发工作，满足各种复杂业务场景下的产品需求；</li>
                      <li>2、与产品、运营部门师一起探讨，设计、实现产品的新功能及改进；</li>
                      <li>3、数据驱动，不断通过产品和技术数据进行改进，并完成快速迭代；</li>
                      <li>4、能够独立完成核心模块的技术攻关以及开发工作；</li>
                      <li>5、学习和研究新技术以更好的满足产品的需求。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、5年以上java开发工作经验，具有扎实的Java编程基础；</li>
                      <li>2、能够独立完成应用系统的开发，具有实际的架构经验；</li>
                      <li>3、熟悉常用的Web开发相关技术，Web框架、HTTP/HTTPS协议、Web安全等；</li>
                      <li>4、掌握关系数据库及SQL相关知识，熟悉至少一种SQL，熟悉基本的设计和优化原则；</li>
                      <li>5、熟悉J2EE，Spring Boot，Spring Cloud等框架；</li>
                      <li>6、熟练使用redis内存型数据库，懂得RabitMQ消息中间件，Elasticsearch、 DB调优与架构者优先；</li>
                      <li>7、具有Java高并发、多线程以及网络通信等经验者优先。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(8)" :class="jobActive==8?'jobcurrens':''">
                    产品内容运营经理
                    <span class="gwicon" :class="jobActive==8?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==8">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、 负责公司产品线上产品各板块的内容运营，对用户留存，活跃负责。</li>
                      <li>2、 开拓多种渠道获得内容，提升内容品质与内容数量。</li>
                      <li>3、 负责产品日常内容的审核与更新。</li>
                      <li>4、 负责产品各版块内容选题与方向，制定内容产出与发布计划。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、统招本科及以上学历，3年以上内容运营相关经验，图书情报专业毕业的优先考虑；</li>
                      <li>2、关注或了解SSCI以及国内CSSCI等核心期刊内容，需要能对所学学科内容方向有个人见解。</li>
                      <li>3、 熟悉图书馆日常工作管理经验值优先。</li>
                      <li>4、 自我学习能力强，对数据敏感。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3 class="job-title" @click="getJobData(9)" :class="jobActive==9?'jobcurrens':''">
                    资源合作
                    <span class="gwicon" :class="jobActive==9?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==9">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、与版权单位沟通，签订著作权使用许可协议；</li>
                      <li>2、负责维护好与已合作单位的友好关系，保障出版任务的顺利进行；</li>
                      <li>3、及时有效的解决合作中存在的任何问题，保证合作关系长期稳定发展；</li>
                      <li>4、负责制定组稿方案，保障相关数据库资源收录的及时性和稳定性；</li>
                      <li>5、宣传推广我中心向合作单位提供的信息化服务；</li>
                      <li>6、维护行业组织关系，关注行业发展动态。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、本科及以上学历；</li>
                      <li>2、有图情期刊资源合作相关经验者有限录用；</li>
                      <li>3、有较强的语言表达能力、沟通能力和文字表达能力；</li>
                      <li>4、心理承受能力强，有自主学习意识；</li>
                      <li>5、诚实稳重，踏实认真，具有很强的人际沟通能力、组织协调能力以及高度的团队合作能力，责任心强；</li>
                      <li>6、分析能力强，思路清晰，考虑问题细致。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3
                    class="job-title"
                    @click="getJobData(10)"
                    :class="jobActive==10?'jobcurrens':''"
                  >
                    用户运营
                    <span class="gwicon" :class="jobActive==10?'active':''"></span>
                  </h3>
                  <div class="job-require" v-show="jobActive==10">
                    <p class="job-sub-title">岗位职责：</p>
                    <ol>
                      <li>1、负责产品C端用户成长体系搭建。建立用户数据模型，对用户来源渠道、基础画像、关键行为进行分析与针对性运营策略。</li>
                      <li>2、拉升用户粘性，促进产品的使用时长、回复率等。</li>
                      <li>3、管理用户标签，熟悉用户标签体系管理。</li>
                      <li>4、负责维系重点用户，促进重点用户在平台上产生高价值内容。</li>
                    </ol>
                    <p class="job-sub-title">任职要求：</p>
                    <ol>
                      <li>1、需要有2年以上的用户运营方面工作经验。</li>
                      <li>2、有知识付费产品用户运营方面经验优先，有校园产品运营经验加分。</li>
                      <li>3、有0到1，1到100的用户运营经历。</li>
                      <li>4、沟通能力强，需要一定抗压能力。</li>
                    </ol>
                    <div class="btn">
                      <a href="mailto:zhaopin@epsnet.com.cn">申请职位（zhaopin@epsnet.com.cn）</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==5">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">新闻动态</span>
            </div>
          </div>
          <div class="listdata">
            <ul class="listSide">
              <li v-for="(v,k) in newsList" :key="k">
                <a :href="edp_url+'html/'+v.newsurl" target="_blank">
                  <div class="title cleft" :title="v.title">
                    <i class="gwicon lis"></i>
                    {{v.title}}
                  </div>
                  <div class="title cright">{{v.pubdate}}</div>
                </a>
              </li>
            </ul>
            <div class="ipage">
              <Page :current="currentPage" v-show="totalSize>pageSize" :page-size="pageSize" :total="totalSize" @on-change="jumpPage" />
            </div>
          </div>
        </div>
        <div class="problock problockBor cright" v-show="sideActive==6">
          <div class="CommontTitle">
            <div class="com_TitleName">
              <span class="com_Name">联系我们</span>
            </div>
          </div>
          <div class="paragraph-box" style="margin-bottom:20px;padding:0 10px;">
            

            <div class="contact-ways" v-if="urlData==='sozdata'">
              <div class="item">
                <div class="name">销售服务</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8002</div>
                <div class="emails">
                  <a href="mailto:sales@sozdata.com">
                    <span class="gwicon email"></span>sales@sozdata.com
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">客户服务</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8001</div>
                <div class="emails">
                  <a href="mailto:service@sozdata.com">
                    <span class="gwicon email"></span>service@sozdata.com
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">数据咨询</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8029</div>
                <div class="emails">
                  <a href="mailto:data@epsnet.com.cn">
                    <span class="gwicon email"></span>data@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">技术支持</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8011</div>
                <div class="emails">
                  <a href="mailto:tech@epsnet.com.cn">
                    <span class="gwicon email"></span>tech@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">项目咨询</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8022</div>
                <div class="emails">
                  <a href="mailto:project@epsnet.com.cn">
                    <span class="gwicon email"></span>project@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">投诉建议</div>
                <div class="telphone"><span class="gwicon phone"></span>010-85786653<span style="padding:0 29px;"></span></div>
                <div class="emails">
                  <a href="mailto:service@epsnet.com.cn">
                    <span class="gwicon email"></span>service@epsnet.com.cn
                  </a>
                </div>
              </div>
            </div>

            <div class="contact-ways" v-else>
              <div class="item">
                <div class="name">销售服务</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8002</div>
                <div class="emails">
                  <a href="mailto:sales@epsnet.com.cn">
                    <span class="gwicon email"></span>sales@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">客户服务</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8001</div>
                <div class="emails">
                  <a href="mailto:service@epsnet.com.cn">
                    <span class="gwicon email"></span>service@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">数据咨询</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8029</div>
                <div class="emails">
                  <a href="mailto:data@epsnet.com.cn">
                    <span class="gwicon email"></span>data@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">技术支持</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8011</div>
                <div class="emails">
                  <a href="mailto:tech@epsnet.com.cn">
                    <span class="gwicon email"></span>tech@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">项目咨询</div>
                <div class="telphone"><span class="gwicon phone"></span>010－85786021转8022</div>
                <div class="emails">
                  <a href="mailto:project@epsnet.com.cn">
                    <span class="gwicon email"></span>project@epsnet.com.cn
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="name">投诉建议</div>
                <div class="telphone"><span class="gwicon phone"></span>010-85786653<span style="padding:0 29px;"></span></div>
                <div class="emails">
                  <a href="mailto:service@epsnet.com.cn">
                    <span class="gwicon email"></span>service@epsnet.com.cn
                  </a>
                </div>
              </div>
            </div>

            <div class="tab contact-table-wrap">
              <div v-html="contactHtml"></div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import axios from "axios";
import Header from "./commons/Header.vue";
import Footer from "./commons/Footer.vue";
import AboutCustom from "./AboutCustom.vue";
import {
  config,
  olapUrl,
  urlSoz
} from "../until/common.js";
export default {
  name: "About",
  components: {
    Header,
    Footer,
    AboutCustom
  },
  data() {
    return {
      urlData:'',
      edp_url:null,
      currentPage:1,
      pageSize:0,
      totalSize:0,
      jobActive: 1,
      sideActive: 1,
      sideList: [
        {id: 1,name: "公司介绍"},
        {id: 5,name: "新闻动态"},
        {id: 2,name: "企业资质"},
        {id: 3,name: "典型用户"},
        {id: 4,name: "招贤纳士"},
        {id: 6,name: "联系我们"}
      ],
      newsList: [],
      contactHtml:''
    };
  },
  computed: {},
  methods: {
    getGroupClick: function(item) {
      this.sideActive = item.id;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getJobData: function(index) {
      this.jobActive = index;
    },
    jumpPage(current){
      this.getlist(current);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getlist(current){
      var that = this;
      axios.get(config.baseUrl+'api/comgedata?sp=News_GetList&p=PageIndex;newsitem&v='+current+';7').then((res)=> {    
        that.newsList = res.data.result[0];
        that.currentPage = res.data.result[1][0].PageIndex;
        that.pageSize = res.data.result[1][0].PageSize;
        that.totalSize = res.data.result[1][0].Total;
      }).catch(function(error) {
        window.console.log(error);
      });
    }
  },
  mounted() {
    var that = this;
    that.edp_url = olapUrl;//(http://edp.epsnet.com.cn/)
    that.getlist(1);
    if(that.$route.query.id){
      that.sideActive = that.$route.query.id;
    }
    if(document&&document.location.hostname===urlSoz){
      that.urlData = 'sozdata'
    }else{
      that.urlData = 'epsdata'
    }
    let type = this.$route.query.type;
    if(type) {
      this.getGroupClick({id:type})
    }

    // axios.get('https://cms.sozdata.com/product/getOne?AUTOID=121').then(res=>{
    //   if(res.data.data) {
    //     this.contactHtml = res.data.data.PCONTENT;
    //   }
    // })
  }
};
</script>
<style lang="scss">
  .contact-table-wrap {
    table {
      width:100% !important;
      tr:first-child {
        background:#D6E7FB !important;
      }
    }
  }
  .contact-ways {
    padding:30px 0;
    .item {
      padding:10px 10px 15px;
      border:1px solid #D6E7FB;
      width:calc(50% - 20px);
      display: inline-block;
      margin-left: 20px;
      margin-bottom: 20px;
      .name {
        display: block;
        padding:5px 0;
      }
      .telphone {
        display: inline-block;
        margin-right: 20px;
        margin-left:20px;
        .phone {margin-right:5px}
      }
      .emails {
        display: inline-block;
        .email {margin-right:5px}
      }
    }
  }
</style>